import * as React from "react";
import { Link } from "gatsby";

export const BreadCrumb = ({ title, to, subTitle }) => {
  return (
    <section className="mb-6 mx-auto px-6 md:px-10 lg:max-w-5xl lg:max-w-5xl">
      <div className="flex items-center justify-end">
        <div className="flex items-center justify-center">
          <span className="mr-2 text-primary-blue text-xs md:text-sm">
            <Link to="/">TOP</Link>
          </span>
          <span className="mr-2 text-xs md:text-sm">/</span>
          <span className="mr-2 text-primary-blue text-xs md:text-sm">
            <Link to={to}>{subTitle}</Link>
          </span>
          <span className="text-xs md:text-sm">{title}</span>
        </div>
      </div>
    </section>
  );
};
