import React from 'react';
import { Seo } from '../components/seo';
import { Layout } from '../layout';
import { BreadCrumb } from '../section/breadcrumb';

import PrivacyImg from '../assets/img/bg-privacy.jpeg';

const PrivacyPolicyPage = () => {
  return (
    <Layout>
      <Seo
        title="個人情報保護方針"
        description="個人情報の取り扱いに際しては、利用目的を明らかにしたうえで、必要な範囲で個人情報の取得・利用を行います。"
      />
      {/* <!-- ヘッダータイトル --> */}
      <section className="mb-6">
        <div
          style={{ backgroundImage: `url(${PrivacyImg})` }}
          className=" relative flex items-center justify-center 2xl:-mt-2 w-screen h-40vh bg-cover bg-center overflow-hidden md:h-60vh"
        >
          <h1 className="flex flex-col items-center justify-center tracking-widest">
            <span className="text-white text-2xl md:text-4xl">
              PRIVACY POLICY
            </span>
            <span className="mt-2 text-white text-xs md:text-base">
              個人情報保護方針
            </span>
          </h1>
          <div>
            <svg
              className=" wave absolute bottom-0 inset-x-0 -mb-1 w-200vw text-white fill-current "
              viewBox="0 0 1440 64"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M360 19.483C240 -6.49433 120 -6.49433 0 19.483V63.5H1440V19.483C1320 45.4603 1200 45.4603 1080 19.483C960 -6.49433 840 -6.49433 720 19.483C600 45.4603 480 45.4603 360 19.483Z" />
            </svg>
          </div>
        </div>
      </section>
      {/* <!-- 現在ページ --> */}
      <BreadCrumb title="個人情報保護方針" />
      {/* <!-- 個人情報保護方針 --> */}
      <section className="mb-12 mx-auto px-6 md:mb-24 md:px-10 lg:mb-32 lg:max-w-4xl">
        <div className="flex flex-col items-center justify-center mb-12 lg:mb-20">
          <div className="mb-2 md:mb-4">
            <h2 className="text-primary-blue md:text-xl">個人情報保護方針</h2>
          </div>
          <div className="w-8 h-0.5 bg-primary-blue md:w-12"></div>
        </div>
        <div className="flex flex-col mb-12 lg:mb-20">
          {[
            {
              title: '個人情報の収集、利用',
              subtitle:
                '個人情報の取り扱いに際しては、利用目的を明らかにしたうえで、必要な範囲で個人情報の取得・利用を行います。',
            },
            {
              title: '個人情報の収集、利用',
              subtitle:
                '個人情報の安全管理と適切な取り扱いを心がけ、あらかじめ本人の同意がある場合や法令の規定がある場合を除き、目的外の利用や第三者への提供をいたしません。',
            },
            {
              title: '正確性の確保',
              subtitle:
                '個人情報を正確かつ最新の状態に保つよう、適切な措置を講じます。',
            },
            {
              title: '安全性の維持',
              subtitle:
                '個人情報の取り扱いに際しては、不正アクセス・紛失・破壊・改ざん及び漏えい等を防止するために、組織上技術上合理的な防止策を講じ、予防に努めます。',
            },
            {
              title: '照会等への対応',
              subtitle:
                '本人からの個人情報の照会・修正・削除などの要望に対し、担当窓口を設置し、可能な範囲で誠実かつ速やかに対処いたします。',
            },
            {
              title: '法令・規範の遵守',
              subtitle:
                '個人情報の取り扱いに際しては、適用される法令・規範を遵守いたします。',
            },
            {
              title: '継続的改善',
              subtitle:
                '個人情報の取り扱いに関する諸規則を定め、継続的な改善に努めることにより、個人情報保護策が常に適切なものであるようにいたします。',
            },
          ].map(({ title, subtitle }, index) => {
            return (
              <div className="mb-6 md:mb-8 lg:mb-12">
                <div className=" flex items-center mb-2 pb-2 w-full border-b-2 border-primary-blue md:pb-3 lg:pb-4">
                  <span className="mr-4 text-primary-blue text-sm md:text-base lg:text-xl">
                    {index + 1}.
                  </span>
                  <span className=" text-primary-blue text-sm tracking-wider md:text-base lg:text-lg">
                    {title}
                  </span>
                </div>
                <div>
                  <p className=" pt-1 text-xs tracking-wider leading-5 md:pt-2 md:text-sm md:leading-6 lg:pt-3 lg:text-base lg:leading-7">
                    {subtitle}
                  </p>
                </div>
              </div>
            );
          })}

          <div className="mb-6 md:mb-8 lg:mb-12">
            <div
              className="
                flex
                items-center
                mb-2
                pb-2
                w-full
                border-b-2 border-primary-blue
                md:pb-3
                lg:pb-4
              "
            >
              <span className="mr-4 text-primary-blue text-sm md:text-base lg:text-xl">
                8.
              </span>
              <span
                className="
                  text-primary-blue text-sm
                  tracking-wider
                  md:text-base
                  lg:text-lg
                "
              >
                個人情報に関するお問い合わせ
              </span>
            </div>
            <div>
              <p
                className="
                  pt-1
                  text-xs
                  tracking-wider
                  leading-5
                  md:pt-2
                  md:text-sm
                  md:leading-6
                  lg:pt-3
                  lg:text-base
                  lg:leading-7
                "
              >
                株式会社ファイン
                <br />
                〒581-0041　大阪府八尾市北木ノ本3-36 <br />
                TEL　072-993-8253 <br />
                FAX　072-993-8622 <br />
                Email　osaka@fine-hq.co.jp <br />
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PrivacyPolicyPage;
